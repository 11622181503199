import { AgnosticPriceValue, AgnosticProduct, AgnosticVariant } from 'shared/types'
import consola from 'consola'

export function getProductPrices(product: AgnosticProduct, selectedVariant?: AgnosticVariant): AgnosticPriceValue[] {
  if (product.type === 'STANDARD') {
    const standardPrice = product.variations?.[0]?.price

    return standardPrice ? [standardPrice] : []
  } else if (product.type === 'VARIATION_MASTER' || product.type === 'VARIATION_GROUP') {
    if (selectedVariant) {
      return selectedVariant.price ? [selectedVariant.price] : []
    } else {
      const variationPrices: AgnosticPriceValue[] = []

      for (const v of product.variations) {
        v.price && variationPrices.push(v.price)
      }

      return variationPrices
    }
  }

  consola.warn(`price for product type ${product.type} has not been covered yet`)

  return []
}

export function getProductPriceRange(prices: AgnosticPriceValue[]): { min: number; max: number } {
  return prices.reduce(
    (acc, currentPrice) => {
      acc.min = Math.min(acc.min, Math.min(currentPrice.regular, currentPrice.special || Infinity))
      acc.max = Math.max(acc.max, Math.min(currentPrice.regular, currentPrice.special || Infinity))

      return acc
    },
    { min: Infinity, max: -Infinity }
  )
}

const SIZES_ORDER = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', '3XL']

export function sortVariantsSizes(a: string | number, b: string | number): number {
  if (Number(a) && Number(b)) {
    return Number(a) - Number(b)
  }

  return SIZES_ORDER.indexOf(String(a).toUpperCase()) - SIZES_ORDER.indexOf(String(b).toUpperCase())
}
